import { useUserStore } from "@/store/user"

export function useEventChannel(loggedIn) {
  const appInstance = getCurrentInstance()?.appContext.config.globalProperties
  const cable = appInstance.$cable
  const userStore = useUserStore()
  const { $bus, $cable } = useNuxtApp()

  const channelHandlers = {
    connected: () => console.debug("EventChannel Connected"),
    rejected: () => console.error("EventChannel rejected"),
    received: (data) => {
      if (data) {
        $bus.$emit(data.subject, data)
      }
    },
    disconnected: () => console.debug("EventChannel disconnected"),
  }

  const subscribeChannel = (channelName, channelHandlers) => {
    cable.subscribe({ channel: channelName, user_id: userStore.userId })
    cable._addChannel(channelName, channelHandlers, {})
  }

  const unsubscribeChannel = (channelName) => {
    cable.unsubscribe(channelName)
  }

  watch(loggedIn, (newValue, oldValue) => {
    if (newValue === true) {
      subscribeChannel("EventChannel", channelHandlers)
    } else {
      unsubscribeChannel("EventChannel")
    }
  })

  onMounted(() => {
    if (loggedIn.value) {
      subscribeChannel("EventChannel", channelHandlers)
    }
  })

  onUnmounted(() => {
    if (loggedIn.value) {
      unsubscribeChannel("EventChannel")
    }
  })
}
