<template>
  <!-- SITE CONSTRAINT -->
  <div class="site-constraint">
    <!-- HEADER -->
    <SiteHeader />

    <!-- BODY -->
    <div class="site-body">
      <!-- NAVIGATION -->
      <SiteMainNav v-if="!isMobile" />

      <!-- CONTENT -->
      <main class="site-content">
        <!-- CONTENT: Search Results -->
        <SearchResults v-if="globalSearchQuery" :query="globalSearchQuery" />

        <!-- CONTENT: Page Content -->
        <slot v-else />
      </main>

      <!-- SIDEBAR -->
      <template v-if="$slots.sidebar && !isMobile">
        <ClientOnly>
          <aside class="site-sidebar scrollable no-scrollbar">
            <slot name="sidebar" />
          </aside>
        </ClientOnly>
      </template>
    </div>

    <!-- MOBILE APP BAR -->
    <SiteMobileAppBar />

    <!-- MODAL: Login -->
    <UModal v-model="loginModalOpen" :ui="modalStyles">
      <div class="p-8 pb-16">
        <LoginForm @logged-in="loggedInCallback" />

        <p class="w-full mx-auto text-center pt-12">
          <span>Not registered yet?&nbsp;</span>
          <NuxtLink to="/join" class="font-bold">Create an Account</NuxtLink>
        </p>
      </div>
    </UModal>

    <!-- MODAL: Signup -->
    <UModal v-model="signupModalOpen" :ui="modalStyles">
      <div class="p-8 pb-16">
        <JoinForm @logged-in="loggedInCallback" />

        <p class="w-full mx-auto text-center pt-12">
          <span>Already have an account?&nbsp;</span>
          <NuxtLink to="/login">Sign in</NuxtLink>
        </p>
      </div>
    </UModal>

    <!-- MODAL: Create Post -->
    <UModal v-model="postModalOpen" :ui="modalStyles">
      <div class="p-8 pb-16">
        <CreatePostForm />
      </div>
    </UModal>

    <!-- MODAL: Age Confirmation -->
    <UModal v-model="ageConfirmationShowing" prevent-close :ui="uiAgeModal">
      <AgeConfirmationModal @confirm="confirmAge" />
    </UModal>

    <!-- MODAL: Token Wallet -->
    <UModal v-model="tokenPurchaseModalOpen" :ui="modalStyles">
      <PurchaseModal initial-step="select-token-amount" @close="tokenPurchaseModalOpen = false" />
    </UModal>

    <UModals />
    <UNotifications />
  </div>
</template>

<script>
export default { name: "Layout" }
</script>

<script setup>
/******************************************************
 *   	Imports
 *****************************************************/
import { useUserStore } from "@/store/user"

/******************************************************
 *   	Composables, Refs & Variables
 *****************************************************/
const config = useRuntimeConfig()
const isMobile = useMediaQuery("(max-width: 767px)")
const isTablet = useMediaQuery("(max-width: 1025px)")
const toast = useToast()
const userStore = useUserStore()
const loggedIn = ref(userStore.loggedIn)

const globalSearchQuery = ref(null)
const tokenPurchaseModalOpen = ref(false)
const loginModalOpen = ref(false)
const signupModalOpen = ref(false)
const postModalOpen = ref(false)

useEventChannel(loggedIn)

const loggedInCallback = () => {
  loginModalOpen.value = false
  signupModalOpen.value = false
  loggedIn.value = true
}

const ageConfirmationShowing = ref(false)

if (userStore.ageConfirmed === false) {
  ageConfirmationShowing.value = true
}

const uiAgeModal = {
  container: "items-start md:items-center",
  overlay: {
    background:
      "bg-gray-200/75 dark:bg-[#1B2022]/75 backdrop-filter backdrop-blur-3xl",
  },
}

const modalStyles = {
  container: 'items-start md:items-center',
  background: 'dark:bg-[#1b2022]',
  overlay: {
    background: 'bg-[#1b2022] dark:bg-black dark:bg-opacity-80'
  }
}

/******************************************************
 *   	Functions
 *****************************************************/
const confirmAge = () => {
  userStore.confirmAge()
  ageConfirmationShowing.value = false
}

/******************************************************
 *   	Event Handlers
 *****************************************************/
const handleGlobalSearchQueryChange = (query) => {
  globalSearchQuery.value = query
}

const resendEmailConfirmation = async () => {
  // try {
  await $api(
    `${config.public.API_URL}/api/me/settings/resend_email_confirmation`,
    {
      method: "POST",
      body: {},
    },
  )

  toast.add({
    title: "Email sent",
    description: "Please check all email folders",
  })
  // } catch (error) {
  //   console.log('resending email confirmation failed', error.data)
  // }
}

/******************************************************
 *   	Providers
 *****************************************************/
provide("handleGlobalSearchQueryChange", handleGlobalSearchQueryChange)
provide("tokenPurchaseModalOpen", tokenPurchaseModalOpen)
provide("loginModalOpen", loginModalOpen)
provide("signupModalOpen", signupModalOpen)
provide("postModalOpen", postModalOpen)
</script>

<style lang="scss" scoped>
.site-constraint {
  @apply h-dvh relative flex flex-1 flex-col justify-between max-w-[1280px] mx-auto px-0 overflow-hidden;
}

.site-body {
  @apply relative w-full px-2;
  @apply flex flex-1 flex-row justify-start items-start;
  @apply sm:gap-4 md:gap-4 overflow-y-hidden;
}

.site-content {
  @apply overflow-y-hidden;
  @apply absolute inset-0 w-full flex flex-grow px-2;
  @apply sm:relative sm:inset-auto sm:top-0 sm:px-0 sm:h-full;
}

.site-sidebar {
  @apply flex flex-col justify-start items-start gap-2;
  @apply h-[calc(100vh-72px)] overflow-y-hidden;
}

.logged-in {
  @apply h-[calc(100vh-132px)];
}

.not-mobile {
  @apply h-[calc(100vh-72px)];
}

.desktop {
  @apply lg:max-w-[310px] lg:min-w-[310px];
}

.tablet {
  @apply md:max-w-[270px] md:min-w-[270px];
  @apply lg:max-w-[270px] lg:min-w-[270px];
}

.scrollable {
  @apply overflow-y-auto;
}

.content-offset {
  @apply mt-[-47px];
}
</style>
