<template>
  <div class="wrapper animate-fade animate-duration-[4000ms]" v-bind="$attrs">

    <!-- Overlay: Center Lock Icon -->
    <div v-if="!showMedia" class="centerIcon">
      <font-awesome-icon icon="fa-light fa-lock-keyhole" class="text-white" />
    </div>

    <!-- Overlay: Video Unlocked Icon -->
    <div
      v-if="!showMedia && type == 'video'"
      class="centerIcon"
    >
      <font-awesome-layers class="fa-stack fa-2x">
        <font-awesome-icon
          icon="fa-solid fa-circle"
          class="fa-stack-2x text-gray-900"
        />
        <font-awesome-icon
          icon="fa-solid fa-play"
          class="fa-stack-1x fa-inverse"
          size="xs"
        />
      </font-awesome-layers>
    </div>

    <!-- Overlay: Video Duration -->
    <div v-if="type === 'video'" class="duration">
      {{ duration }}
    </div>

    <!-- Compliance Badge -->
    <div v-if="userStore.compliance" class="badge">Viewing as Compliance</div>

    <!-- Override Overlay: Lock -->
    <div v-if="overrideLock && locked" class="absolute rounded-md top-1 right-0 z-10">
      <UPopover :popper="{ offsetDistance: 10, placement: 'bottom-start' }" mode="hover">
        <font-awesome-layers
          class="fa-stack fa-1x"
        >
          <font-awesome-icon
            icon="fa-solid fa-circle"
            class="fa-stack-2x text-gray-900 opacity-75"
          />
          <font-awesome-icon
            icon="fa-solid fa-lock"
            class="fa-stack-1x text-white"
            size="xs"
          />
        </font-awesome-layers>

        <template #panel>
          <div class="flex flex-col items-center gap-2 px-4 py-2 max-w-[215px] text-[10px] z-10">
            Locked Media
          </div>
        </template>
      </UPopover>
    </div>

    <!-- Override Overlay: NSFW Badge -->
    <div v-if="overrideLock && nsfw" class="absolute top-2 left-2 z-10">
      <!-- <span class="bg-red-500 text-white text-[10px] px-1.5 pt-0.5 pb-1 rounded-full">NSFW</span> -->
      <UPopover :popper="{ offsetDistance: 10, placement: 'bottom-start' }" mode="hover">
        <UBadge color="black" variant="solid" size="xs" :ui="{ size: { xs: 'text-[10px] px-1.5 py-0.5' }}">NSFW</UBadge>

        <template #panel>
          <div class="flex flex-col items-center gap-2 px-4 py-2 max-w-[215px] text-[10px] z-10">
            Not Safe For Work
          </div>
        </template>
      </UPopover>
    </div>

    <!-- Override Overlay: Status Badge - Rejected -->
    <div v-if="showStatusBanner && status.toLowerCase() == 'rejected'" class="absolute z-10 bottom-2 w-full max-w-[95%] mx-2">
      <UPopover :popper="{ offsetDistance: 10, placement: 'top-start' }" mode="hover">
        <div class="w-full flex flex-row text-center justify-center items-center bg-red-500 text-white rounded-lg p-1.5 text-xs text-center;">
          <font-awesome-icon
            icon="fa-solid fa-info-circle"
            class="text-white"
          />
          <div class="w-full leading-none">{{ mediaStatus }}</div>
        </div>

        <template #panel>
          <div class="flex flex-col items-center gap-2 p-4 max-w-[215px] text-[10px] z-10">
            <span class="font-bold w-full">Rejected</span>
            {{ rejected }}
          </div>
        </template>
      </UPopover>
    </div>

    <!-- Override Overlay: Status Badge - In Review -->
    <div v-if="showStatusBanner && ['Processing', 'Processed', 'UserTagsNeeded'].includes(status)" class="absolute z-10 bottom-2 w-full max-w-[95%] mx-2">
      <UPopover :popper="{ offsetDistance: 10, placement: 'top-start' }" mode="hover">
        <div class="w-full flex flex-row text-center justify-center items-center bg-black text-white rounded-lg p-1.5 text-xs text-center;">
          <font-awesome-icon
            icon="fa-solid fa-timer"
            class="text-white"
          />
          <div class="w-full leading-none" v-if="status == 'Processing'">Processing</div>
          <div class="w-full leading-none" v-if="status == 'Processed'">In Review</div>
          <div class="w-full leading-none" v-if="status == 'UserTagsNeeded'">User Tags Needed</div>
        </div>

        <template #panel>
          <div class="flex flex-col gap-2 p-4 max-w-[215px] text-[10px] z-10">
            <span class="font-bold" v-if="status == 'Processing'">Processing</span>
            <p v-if="status == 'Processing'">Your media content is currently being processed.</p>
            <span class="font-bold" v-if="status == 'Processed'">In Review</span>
            <p v-if="status == 'Processed'">All uploaded media content is subject to review, ensuring it meets the Community Standards.</p>
            <span class="font-bold" v-if="status == 'UserTagsNeeded'">User Tags Needed</span>
            <p v-if="status == 'UserTagsNeeded'">Please add creator tags to this upload.</p>
          </div>
        </template>
      </UPopover>
    </div>

    <!-- Media Unlocked -->
    <div
      v-bind="$attrs"
      v-if="showMedia"
      class="mediaCover"
      :class="{ locked: !showMedia }"
      :style="`background-image: url('${url}')`"
    />

    <!-- Media Locked -->
    <BlurHashThumb
      v-else
      v-bind="$attrs"
      :blur-hash="blurhash"
    />
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const props = defineProps({
    blurhash:       { type: String,   required: false },
    creator:        { type: Object,   required: false },
    locked:         { type: Boolean,  default: false },
    duration:       { type: Number,   default: 0 },
    gallery:        { type: Array,    default: [] },
    originalHeight: { type: Number,   required: false },
    originalWidth:  { type: Number,   required: false },
    src:            { type: String,   required: false },
    thumbnail:      { type: String,   required: false },
    type:           { type: String,   required: false },
    hasAccess:      { type: Boolean,  default: false },
    modalEnabled:   { type: Boolean,  default: true },
    overrideLock:   { type: Boolean,  default: false },
    status:         { type: String,   default: null },
    nsfw:           { type: Boolean,  default: false },
    rejected:       { type: String,   default: null }
  })

  const detailsShowing = ref(false)
  const userStore = useUserStore()


  const showMedia = computed(() => {
    // Is Profile Page
    if (props.overrideLock) {
      return true
    } else if (props.locked) {
      if (props.hasAccess) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  })

  const showStatusBanner = computed(() => {
    return props.overrideLock && !['approved', 'manuallyreviewed'].includes(props.status?.toLowerCase())
  })

  const url = computed(() => {
    if (props.locked && !showMedia.value) {
      return "https://favoritely-assets.s3.us-west-1.amazonaws.com/staging/assets/photo_placeholders/250x250.png"
    } else {
      return props.thumbnail
    }
  })

  const mediaStatus = computed(() => {
    if (props.status === "rejected") {
      return "Rejected"
    } else if (['Processing', 'Processed'].includes(props.status)) {
      return "In Review"
    } else {
      return props.status
    }
  })

  const handleMediaClick = () => {
    if (props.modalEnabled) {
      detailsShowing.value = true
    }
  }

  const badgeStyles = {
    rounded: 'rounded-lg',
    size: {
      xs: 'text-[10px] px-2 py-1 pt-1.5'
    },
    color: {
      primary: {
        solid: 'text-gray-900 dark:text-pink-500 bg-pink-600 dark:bg-gray-800'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    @apply relative overflow-hidden rounded-lg;
  }
  .locked {
    @apply bg-cover bg-center bg-no-repeat;

    filter: blur(32px);
    transform: scale(1.7);
  }
  .badge {
    @apply absolute top-1.5 left-1 w-min text-white text-xs whitespace-nowrap rounded-full bg-red-600/80 px-2 py-1.5 z-[1];
  }
  .duration {
    @apply absolute right-1 top-1.5 z-[1] text-white;

    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  }
  .centerIcon {
    @apply absolute inset-0 z-[2] flex items-center justify-center;
  }
  .mediaCover {
    @apply relative text-white cursor-pointer bg-cover bg-center bg-no-repeat;
  }

  .statusBadge {
    @apply absolute z-10 bottom-2  w-full max-w-[95%] mx-2 flex flex-row justify-center items-center gap-1 bg-black text-white rounded-lg p-1.5 text-xs text-center;

    &.rejected {
      @apply bg-red-500;
    }

    &.processed {
      @apply bg-black;
    }
  }
</style>
