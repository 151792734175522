<template>
  <div
    class="sm:hidden px-6 py-2.5 bg-white dark:bg-[#0C0E0F] shadow flex flex-row items-center justify-between gap-4"
  >
    <NuxtLink to="/">
      <font-awesome-icon
        icon="fa-light fa-house"
        class="text-xl text-gray-400"
      />
    </NuxtLink>

    <NuxtLink to="/notifications" class="relative">
      <span v-if="userStore.loggedIn && notificationCounts && notificationCounts.unread" class="absolute -top-2 -right-2 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-primary-500 rounded-full">{{ notificationCounts.unread }}</span>
      <font-awesome-icon
        icon="fa-light fa-bell"
        class="text-xl text-gray-400"
      />
    </NuxtLink>

    <Button
      :iconOnly="true"
      size="xl"
      class="flex"
      v-if="userStore.loggedIn"
      @click="showPostForm"
    >
      <font-awesome-icon icon="fa-light fa-plus" />
    </Button>

    <NuxtLink to="/messages">
      <font-awesome-icon
        icon="fa-light fa-envelope"
        class="text-xl text-gray-400"
      />
    </NuxtLink>

    <div class="relative">
      <UPopover overlay :popper="{ placement: 'top-end' }">
        <div>
          <UAvatar
            v-if="userStore.loggedIn"
            size="sm"
            :src="userStore.avatarUrl"
          />
          <font-awesome-icon
            v-else
            icon="fa-light fa-circle-user"
            class="text-xl text-gray-400"
          />
        </div>

        <template #panel="{ close }">
          <SiteExpandedNav :close="close" />
        </template>
      </UPopover>
    </div>
  </div>

  <UModal v-model="createPostShowing" :ui="modalStyles" fullscreen>
    <Modal class="dark:border-b-2 dark:border-black">
      <CreatePostForm @broadcast-feed-update="handleNewPost"/>
    </Modal>
  </UModal>

  <UModal
    v-model="becomeACreatorShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <div class="flex flex-col gap-3 px-4 py-6">
      <h2 class="text-xl">
        Be the next and only
        <br class="hidden md:block lg:block" />
        <span class="text-primary-600">Favorite</span>
        for your fans
      </h2>

      <p class="mb-2">
        Start earning money by creating content for your fans. Get started by
        verifying your account.
      </p>

      <div class="text-center">
        <Button
          size="xl"
          class="text-xl"
          @click="navigateTo('/settings/verification')"
        >
          Get Started
        </Button>
      </div>
    </div>
  </UModal>
</template>

<script setup lang="ts">
  import { useUserStore } from "@/store/user"

  const userStore = useUserStore()
  const becomeACreatorShowing = ref(false)
  const createPostShowing = ref(false)

  const showPostForm = () => {
    if (userStore.approvedCreator) {
      createPostShowing.value = true
    } else {
      becomeACreatorShowing.value = true
    }
  }

  const modalStyles = {
    container: 'items-start md:items-center !w-screen',
    background: 'dark:bg-[#1b2022]',
    overlay: {
      background: 'dark:bg-[#1b2022] dark:bg-opacity-50'
    },
    fullscreen: 'w-screen h-auto'
  }

  const handleNewPost = () => {
    createPostShowing.value = false
  }
  const config = useRuntimeConfig()
  const { data: notificationCounts } = await useAuthFetch(config.public.API_URL + "/api/notifications/unread_count")
</script>
