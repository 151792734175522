<template>
  <header class="site-header">
    <!-- NAVIGATION -->
    <div class="branding">
      <!-- <Logo variant="square" size="h-12"  class="sm:hidden pl-2" /> -->
      <Logo variant="full" size="h-8"     class="hidden flex lg:flex" />
      <Logo variant="square" size="h-5"   class="hidden sm:flex md:flex lg:hidden" />
      <UPopover overlay :popper="{ placement: 'bottom-end' }">
        <div class="sm:hidden pl-2">
          <img :src="imageUrl" class="h-12" />
        </div>

        <template #panel="{ close }">
          <SiteExpandedNav :close="close" />
        </template>
      </UPopover>
    </div>

    <!-- CONTENT -->
    <div class="searchbar">
      <SearchBar class="flex" :query="globalSearchQuery" />
    </div>

    <!-- SIDEBAR -->
    <ClientOnly>
      <div :class="isTablet ? 'tablet-sidebar' : 'site-sidebar'">
        <TokenCountWithButton :user="userStore" />
      </div>
    </ClientOnly>
  </header>
</template>

<script setup>
  import { useUserStore } from "@/store/user"
  const userStore = useUserStore()
  const isTablet = useMediaQuery("(max-width: 1025px)")
  const colorMode = useColorMode()

  const globalSearchQuery = ref(null)

  const imageUrl = computed(() => {
    return colorMode.value == "dark"
      ? "/images/logo-square-dark-background.svg"
      : "/images/logo-square-light-background.svg"
  })
</script>

<style lang="scss" scoped>
  .site-header {
    @apply max-h-8 my-4 px-2;
    @apply w-full flex flex-row justify-start items-center gap-4;
  }
  .branding {
    @apply h-[23px] md:min-w-[52px] lg:min-w-[160px] lg:max-w-[160px] my-auto pt-2;
    @apply flex flex-row justify-center items-center;
  }
  .searchbar {
    @apply w-full h-8;
    height: calc(2rem + 12px);
  }
  .searchbar div {
    height: calc(2rem + 12px);
  }
  .site-sidebar {
    @apply lg:max-w-[340px] lg:min-w-[340px];
  }
  .tablet-sidebar {
    @apply md:max-w-[270px] md:min-w-[270px];
    @apply lg:max-w-[270px] lg:min-w-[270px];
  }
</style>
