<template>
  <video
    ref="videoRef"
    class="w-full"
    playsinline
    autoplay
    :controls="showControls"
    @ended="isPlaying = false"
    @mouseenter="showControls = true"
    @mouseleave="showControls = false"
    @touchstart="handleInteraction"
  >
    <source :src="src" type="video/mp4" />
  </video>
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true
  }
})

const videoRef = ref(null)
const showControls = ref(false)
const isPlaying = ref(false)

const togglePlay = () => {
  if (videoRef.value.paused) {
    videoRef.value.play()
    isPlaying.value = true
  } else {
    videoRef.value.pause()
    isPlaying.value = false
  }
}

const handleInteraction = () => {
  showControls.value = true
  // Hide controls after 3 seconds of inactivity
  setTimeout(() => {
    showControls.value = false
  }, 3000)
}

onMounted(() => {
  if (videoRef.value) {
    videoRef.value.addEventListener("ended", () => {
      isPlaying.value = false
    })
  }
})

onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.removeEventListener("ended", () => {
      isPlaying.value = false
    })
  }
})
</script>
