<template>
  <div class="flex flex-row items-center justify-between">
    <div class="flex flex-row items-center gap-3">
      <NuxtLink
        v-if="isCreator"
        :to="`/${user.username}`"
      >
        <Avatar :size="size" :src="avatarUrl" />
      </NuxtLink>
      <div class="flex flex-col items-start justify-between">
        <div class="flex flex-row items-center gap-1">
          <NuxtLink
            v-if="isCreator"
            :to="`/${user.username}`"
            class="max-sm:w-[110px] text-gray-700 dark:text-white outline-none focus:outline-none truncate"
          >
            <span class="font-semibold text-sm max-sm:text-xs">
              {{ user.displayName }}
            </span>
          </NuxtLink>
          <div
            v-else
            class="max-sm:w-[110px] text-gray-700 dark:text-white outline-none focus:outline-none truncate"
          >
            <span class="font-semibold text-sm max-sm:text-xs">
              {{ user.displayName }}
            </span>
          </div>
          <font-awesome-icon
            v-if="isCreator"
            icon="fa-solid fa-badge-check"
            class="text-pink-600"
          />
        </div>
        <div class="w-full leading-4 flex flex-row items-center gap-1 text-gray-400">
          <div
            class="flex-1 max-sm:w-[110px] truncate"
          >
            <span class="text-xs max-sm:text-[0.7rem]">@{{ user.username }}</span>
          </div>
          <Button
            v-if="isCreator"
            @click="qrCodeModalIsOpen = true"
            theme="tertiary"
            shape="square"
            :iconOnly="true"
            size="xs"
            class="text-[8px] p-[0.4rem]"
            title="Share Profile"
          >
            <font-awesome-icon
              icon="fa-solid fa-up-from-bracket"
              class="fa-fw"
            />
          </Button>
        </div>

        <UModal
          v-model="qrCodeModalIsOpen"
          :ui="{
            width: 'w-full sm:w-[400px] rounded-2xl',
            container: 'items-start md:items-center',
          }"
        >
          <Modal title="Share Profile" @close="qrCodeModalIsOpen = false">
            <div class="flex justify-center p-12">
              <vue-qr
                :dot-scale="0.75"
                :margin="10"
                :size="2048"
                :text="qrCodeUrl"
                :white-margin="false"
                class="w-[160px]"
              />
            </div>
            <UDivider />
            <div class="flex flex-col justify-start gap-1.5">
              <p>Profile Link</p>
              <UButtonGroup size="sm" orientation="horizontal">
                <UInput :disabled="true" v-model="qrCodeUrl" class="w-full" />
                <UButton
                  icon="i-heroicons-clipboard-document"
                  color="gray"
                  @click="copyToClipboard"
                />
              </UButtonGroup>
            </div>

            <!-- TODO: Put this back in -->
            <!-- <UDivider />
            <p>Share QR</p>
            <div class="flex flex-row gap-2 text-sm text-gray-400">
              <font-awesome-icon
                icon="fa-brands fa-x-twitter"
                class="rounded-full p-3 text-gray-400 border border-gray-300 dark:border-gray-800"
              />
              <font-awesome-icon
                icon="fa-brands fa-instagram"
                class="rounded-full p-3 text-gray-400 border border-gray-300 dark:border-gray-800"
              />
              <font-awesome-icon
                icon="fa-brands fa-facebook"
                class="rounded-full p-3 text-gray-400 border border-gray-300 dark:border-gray-800"
              />
            </div> -->
          </Modal>
        </UModal>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    user: {
      type: Object,
      required: true,
    },

    size: {
      type: String,
      default: "md",
    },
  })

  const copyToClipboardButtonText = ref("Copy to Clipboard")

  const isCreator = computed(() => {
    // TODO: clean this API up
    return props.user?.isCreator || props.user?.approvedCreator || false
  })

  const avatarUrl = computed(() => {
    if (props.user.avatarUrl) {
      return props.user.avatarUrl
    } else {
      return "https://favoritely-assets.s3.us-west-1.amazonaws.com/staging/assets/photo_placeholders/250x250.png"
    }
  })

  const qrCodeModalIsOpen = ref(false)
  const qrCodeUrl = computed(() => {
    let url = `https://favorite.ly/${props.user.username}`
    if (props.user.affiliateCode) {
      url += `?t=${props.user.affiliateCode}`
    }
    return url
  })

  const toast = useToast()

  // TODO: Copy to clipboard not working
  const copyToClipboard = async () => {
    if (typeof navigator.clipboard === "object") {
      await navigator.clipboard.writeText(qrCodeUrl.value)
      toast.add({ title: "Copied!" })
    } else {
      toast.add({
        title: "Clipboard not supported",
        color: "red",
      })
    }
  }

  const qrCodeAvatarUrl = computed(() => {
    if (props.user.avatarUrl.includes("placeholder")) {
      return "/images/logo-square-250x250.png"
    } else {
      return props.user.avatarUrl
    }
  })
</script>
