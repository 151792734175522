<template>
  <div class="w-full rounded-xl bg-white p-1 dark:bg-[#1b2022]">
    <div
      class="flex flex-row items-center justify-between rounded-xl px-6 py-3 bg-cool-100 dark:bg-[#1b2022]"
    >
      <div class="flex flex-row items-center gap-6">
        <font-awesome-icon
          icon="fa-light fa-chevron-left"
          @click="$emit('navigate-back')"
        />
        <div>
          <span class="font-bold">{{ label }}</span>
          <MediaPackSummary
            :image-count="imageCount"
            :video-count="videoCount"
            :free="true"
            :text="false"
          />
        </div>
      </div>

      <UModal v-model="searchModalOpen" :ui="{ container: 'items-start md:items-center' }">
        <Modal title="Search Media" @close="searchModalOpen = false">
          <div class="flex flex-col gap-3 p-4">
            <Input label="Search" placeholder="What are you looking for?" />
            <Button>Search</Button>
          </div>
        </Modal>
      </UModal>

      <IconToolbar :tools="toolbarTools">
        <template #sort>
          <!--
          <URadioGroup
            model-value="all"
            :options="[
              { value: 'all', label: 'All Time' },
              { value: 'date', label: 'Go to Date' },
            ]"
            class="w-full"
          />
          <UDivider />
          -->
          <URadioGroup
            v-model="_sortingOrder"
            :options="[
              { value: 'asc', label: 'Ascending' },
              { value: 'desc', label: 'Descending' },
            ]"
            class="w-full"
          />
        </template>

        <template #more>
          <VerticalNavigation 
            :links="moreLinks" 
            :ui="{ size: 'text-xxs' }" 
          />
        </template>
        
      </IconToolbar>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    imageCount: {
      type: Number,
      default: 0,
    },

    label: {
      type: String,
      required: true,
    },

    itemsSelected: {
      type: Array,
      default: []
    },

    sortingOrder: {
      type: String,
      default: "desc",
    },

    videoCount: {
      type: Number,
      default: 0,
    },
  })
  
  const emit = defineEmits([
    "sorting-order-change", 
    "tag-selected"
  ])

  
  const searchModalOpen = ref(false)
  const _sortingOrder = ref(props.sortingOrder)

  const toolbarTools = [
    {
      key: "sort",
      label: "Sort",
      icon: "fa-light fa-bars-filter",
    },
    // {
    //   key: "search",
    //   label: "Search",
    //   icon: "fa-light fa-magnifying-glass",
    //   click: () => (searchModalOpen.value = true),
    // },
    {
      key: "more",
      label: "More",
      icon: "fa-light fa-ellipsis",
    },
  ]
  
  const moreLinks = [
    {
      icon: "fa-light fa-pen",
      label: "Tag Selected",
      click: () => emit("tag-selected"),
    },
  ]

  watch(_sortingOrder, async (newOrder, oldOrder) => {
    emit("sorting-order-change", newOrder)
  })
</script>
