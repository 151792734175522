<template>
  <!-- Payment Type is Card -->
  <div v-if="paymentType == 'card'">
    <USelectMenu v-model="paymentForm.data.paymentMethodSelected" :options="selectOptions" placeholder="Select a card"
      :ui="uiButtonStyles" :uiMenu="uiMenuStyles">
      <template #label>
        <div class="flex flex-row items-center gap-4">
          <img width="35" height="24" :src="getBadgeImageUrl(paymentForm.data.paymentMethodSelected?.type)" />
          <span>
            {{ paymentForm.data.paymentMethodSelected?.label }}
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex flex-row items-center gap-4">
          <img width="35" height="24" :src="getBadgeImageUrl(option.type)" />
          <span>
            {{ option.label }}
          </span>
        </div>
      </template>
    </USelectMenu>
  </div>

  <!-- Payment Type is Paypal -->
  <div v-if="paymentType == 'paypal'" class="option items-center justify-center" :class="{ selected: selected }"
    @click="$emit('option-selected', 'paypal')">
    <img width="104" height="36" src="/images/payment-paypal.png" class="mx-auto" />
  </div>
</template>

<script setup>
const paymentForm = inject('form')

const props = defineProps({
  selected: Boolean,
  type: String,
  paymentAccounts: Array,
})

const emit = defineEmits([
  "option-selected"
])


const paymentType = ref(props.type)
const selectedCard = ref(null)

const selectOptions = computed(() => {
  return props.paymentAccounts.map((account) => {
    if (account.expiresAt > new Date().toISOString()) {
      return {
        type: account.cardType,
        primary: account.primaryAccount,
        label: `**** **** **** ${account.lastFour}`,
        value: account.id,
      }
    }
  }).filter(Boolean)
})

const getBadgeImageUrl = (type) => {
  switch (type) {
    case 'visa':
      return '/images/payment-visa-badge.png'
    case 'mastercard':
      return '/images/payment-mastercard-badge.png'
    default:
      return '/images/payment-ssl-badge.png'
  }
}

const hasPaymentAccounts = computed(() => {
  return props.paymentAccounts.length > 0
})

const primaryPaymentAccount = computed(() => {
  return props.paymentAccounts.find((account) => account.primaryAccount)
})

if (!hasPaymentAccounts.value || props.paymentAccounts.length == 1) {
  emit('option-selected', 'card')
}

if (props.paymentAccounts.length) {
  paymentForm.data.paymentMethodSelected = selectOptions.value.find((option) => option.value === primaryPaymentAccount.value.id)
}

const uiButtonStyles = {
  color: {
    white: {
      outline: 'p-4 shadow-sm bg-white dark:bg-[#1B2022] text-gray-400 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400'
    }
  }
}

const uiMenuStyles = {
  background: "bg-white dark:bg-[#1B2022] dark:text-white",
  input: "block w-[calc(100%+0.5rem)] focus:ring-transparent text-sm px-3 py-1.5 text-gray-700 dark:text-white bg-white dark:bg-[#1B2022] border-0 border-b border-gray-200 dark:border-gray-700 sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-gray-400 dark:placeholder-gray-500 focus:outline-none",
  option: {
    active: 'bg-gray-200 dark:bg-[#2B3236] dark:text-white',
  }
}
</script>

<style lang="scss" scoped>
.option {
  @apply flex flex-row items-center justify-between p-4 rounded-lg bg-cool-200 cursor-pointer;
  @apply hover:bg-cool-100 dark:bg-cool-800 dark:hover:bg-cool-700;

  &.selected {
    @apply bg-cool-100 dark:bg-cool-700;
  }
}
</style>