<template>
  <Button v-if="!userStore.loggedIn" theme="primary" :block="block" :size="size" @click="loginModalOpen = true">
    View now!
  </Button>
  <Button v-if="userStore.loggedIn && !owned && postCost > 0 && !postPurchased" theme="primary" :block="block"
    :size="size" @click="handlePostPurchase">
    Unlock for {{ postCost }} tokens
  </Button>
  <Button v-if="userStore.loggedIn && owned" theme="primary" :block="block" :size="size" @click="() => { }">
    View Media
  </Button>
</template>

<script setup>
import { useUserStore } from "@/store/user"

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  block: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: 'md'
  }
})

const emit = defineEmits([
  "post-purchased"
])

const loginModalOpen = inject("loginModalOpen")
const userStore = useUserStore()
const postPurchased = ref(props.post.purchased)
const postCost = ref(props.post.tokenCount)

const handlePostPurchase = () => {
  emit("post-purchase", props.post.id)
  postPurchased.value = true
}

const owned = computed(() => {
  return props.post.creator.username == userStore.username
})

</script>
