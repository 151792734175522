<template>
  <UModal :fullscreen="true" :preventClose="true" :ui="modalStyles">
    <div class="p-2 pb-0 flex flex-col items-center justify-center h-full">
      <MediaModalHeader :creator="creator" :status="slideMedia.status" :slideIndex="slideIndex"
        :slideCount="gallery.length" :slideId="slideMedia.id" :editable="editable"
        @edit="launchTagEditor(slideMedia.id)" @close="handleClose(true)" />
      <swiper :space-between="50" :initialSlide="media" :thumbs="{ swiper: thumbsSwiper }" :modules="modules"
        :zoom="true" class="w-[98%] h-full m-2 rounded-lg" @slideChange="handleSlideChange">
        <swiper-slide v-for="asset in gallery" :key="asset.id">
          <MediaModalSlide class="swiper-zoom-container" :asset="asset" :mode="mode" :referenceId="referenceId"
            :createdAt="createdAt" @post-purchase="handlePostPurchase" @message-purchase="handleMessagePurchase" />
        </swiper-slide>
      </swiper>
    </div>
    <MediaModalTagsForm v-if="showTagsForm" :media="slideMedia" :tags="slideMedia.tags" @refresh="emit('refresh')"
      @close="handleClose(false)" />
  </UModal>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue"

import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"
import "swiper/css/zoom"

import { FreeMode, Navigation, Thumbs, Pagination, Zoom, Mousewheel } from "swiper/modules"

const props = defineProps({
  mode: {
    type: String,
    default: "post", // post, message
  },

  referenceId: {
    type: String, // UUID of the post or message
    default: null
  },

  media: {
    type: Object,
    required: true,
  },

  gallery: {
    type: Array,
    required: true,
  },

  editable: {
    type: Boolean,
    default: false
  },

  creator: {
    type: Object,
    required: false,
    default: null
  },

  createdAt: {
    type: String,
    required: false
  }
})

const emit = defineEmits([
  "edit",
  "close",
  "refresh",
  "post-purchase",
  "message-purchase"
])

const slideIndex = ref(1)
const slideMedia = ref(props.media)
const showTagsForm = ref(false)
const thumbsSwiper = ref(null)
const modules = [FreeMode, Navigation, Thumbs, Zoom, Mousewheel]


const handleSlideChange = (event) => {
  if (typeof event.activeIndex === 'number') {
    slideIndex.value = event.activeIndex + 1
    slideMedia.value = props.gallery[event.activeIndex]
  }
}

const handlePostPurchase = (postId) => {
  console.log('------> MediaModal handlePostPurchase', postId)
  emit("post-purchase", postId)
}

const handleMessagePurchase = (messageId) => {
  emit("message-purchase", messageId)
}

const launchTagEditor = (mediaId) => {
  showTagsForm.value = true
}

const handleClose = (value) => {
  showTagsForm.value = false

  if (props.editable && value) {
    emit('close')
  } else if (value) {
    emit('close')
  }
}

const modalStyles = {
  container: 'items-start md:items-center',
  background: 'dark:bg-[#1b2022]',
  overlay: {
    background: 'bg-[#1b2022] dark:bg-black dark:bg-opacity-80'
  }
}

const badgeStyles = {
  color: {
    black: {
      solid: 'text-white dark:text-white bg-gray-900 dark:bg-gray-900'
    }
  }
}
</script>