<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-6">
      <IdentityCard :user="creator" />
      <UDivider />
      <PurchaseModalPaymentMethodSelector v-model="form.data.paymentMethodSelected"
        :hasPaymentAccounts="hasPaymentAccounts" :payment-accounts="paymentAccounts"
        :available-payment-methods="availablePaymentMethods" />
    </div>

    <div class="flex flex-col gap-1 text-sm">
      <div class="flex flex-row items-center justify-between">
        <span>Subtotal:</span>
        <span>{{ USDollar.format(subTotal / 100) }}</span>
      </div>
      <div class="flex flex-row items-center justify-between">
        <span>Merchant Processing:</span>
        <span>{{ USDollar.format(merchantProcessingFee / 100) }}</span>
      </div>
      <div class="flex flex-row items-center justify-between">
        <span class="font-bold">Total (renews monthly):</span>
        <span class="font-bold">{{ USDollar.format(total / 100) }}</span>
      </div>
    </div>

    <UDivider class="dark:text-[#555F63]" />

    <span class="text-sm text-cool-300 dark:text-[#555F63]">
      Your subscription will auto-renew unless cancelled. This charge will
      appear on your billing statement as
      <span class="font-semibold">"Favoritely"</span>
      .
    </span>

    <UCheckbox v-model="form.data.agreeToTerms" color="pink" class="flex flex-row items-center gap-2"
      :class="{ 'border-2 border-red-500 rounded-lg p-1': form.errors.agreeToTerms }">
      <template #label>
        <span class="text-base leading-relaxe text-sm">
          I agree to the
          <NuxtLink to="/general-terms-of-service" target="_blank">
            Terms &amp; Conditions
          </NuxtLink>
          ,
          <NuxtLink to="/privacy" target="_blank">Privacy Policy</NuxtLink>
          , and recurring charges of
          {{ USDollar.format(total / 100) }}
          monthly for this subscription.
        </span>
      </template>
    </UCheckbox>

    <div v-if="errors" class="flex flex-col gap-0">
      <span v-for="(err, i) in errors" :key="i" class="text-sm text-red-500">{{ err }}</span>
    </div>

    <Button block theme="primary" size="lg" :loading="loading" @click="nextStep">
      Subscribe for {{ USDollar.format(total / 100) }}
    </Button>

    <span class="text-sm text-cool-300 dark:text-[#555F63]">
      You can cancel at any time through our website, by email
      <a href="mailto:support@favorite.ly">support@favorite.ly</a>
      , or by contacting customer support at
      <a href="tel:1-866-582-4430">1-866-582-4430.</a>
    </span>
  </div>
</template>

<script setup>
import { useUserStore } from "@/store/user"

const purchaseSubscription = inject('purchaseSubscription')
const availablePaymentMethods = inject('availablePaymentMethods')
const form = inject('form')

const props = defineProps({
  creator: {
    type: Object,
    required: true,
  },

  paymentAccounts: {
    type: Array,
    required: true,
  },

  loading: {
    type: Boolean,
    default: false
  }
})

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const emit = defineEmits([
  "close",
  "move-to-step",
  "subscription-purchased"
])

const merchantProcessingFee = 100 // Where should this value come from?
const subscriptionPrice = props.creator.subscriptionPriceInCents
const subTotal = subscriptionPrice
const total = subscriptionPrice + merchantProcessingFee

const hasPaymentAccounts = computed(() => {
  return props.paymentAccounts.length > 0
})

const valid = computed(() => {
  form.errors.agreeToTerms = !form.data.agreeToTerms
  form.errors.paymentMethodSelected = !form.data.paymentMethodSelected
  return Object.values(form.errors).filter(Boolean).length === 0
})

const nextStep = () => {
  if (valid.value) {
    if (hasPaymentAccounts.value) {
      if (form.data.paymentMethodSelected) {
        if (form.data.agreeToTerms) {
          purchaseSubscription()
        } else {
          form.errors.agreeToTerms = true
        }
      } else {
        form.errors.paymentMethodSelected = true
      }

    } else {
      emit("move-to-step", { step: "enter-new-card" })
    }
  } else {
    toast.add({ title: "Please check for errors" })
  }
}

const config = useRuntimeConfig()
const userStore = useUserStore()
const toast = useToast()

watch(() => form.data.agreeToTerms, (newValue) => {
  if (newValue) {
    form.errors.agreeToTerms = false
  }
})
</script>
