<template>
  <div class="flex flex-row items-center gap-2">
    <div v-for="tool in tools" class="flex items-center justify-center">
      <UPopover
        v-if="popover(tool)"
        :popper="{ arrow: true, placement: 'bottom' }"
        class="-mb-2"
      >
        <font-awesome-icon
          :key="tool.label"
          :icon="tool.icon"
          class="rounded-full p-2 aspect-t transition transition-colors hover:bg-pink-600 hover:text-white"
        />
        <template #panel>
          <div class="flex flex-col gap-1 p-2">
            <slot :name="tool.key" />
          </div>
        </template>
      </UPopover>

      <font-awesome-icon
        v-else
        :key="tool.label"
        :icon="tool.icon"
        class="rounded-full p-2 aspect-t transition transition-colors hover:bg-pink-600 hover:text-white"
        @click="performAction(tool)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  tools: {
    type: Array,
    required: true,
  },
})

const popover = (tool) => {
  return !tool.click
}

const performAction = (tool) => {
  if (tool.click) {
    tool.click()
  }
}
</script>
