<template>
  <ClientOnly>
    <UForm
      :schema="schema"
      :state="form.data"
      class="space-y-4 animate-fade"
      @submit="handleSubmit"
    >
      <!-- Error Box -->
      <ErrorBox :errors="formErrors" />

      <!-- CARD NUMBER -->
      <FormGroup label="Card Number" name="cardNumber" hint="We accept (Visa, Mastercard) ">
        <UInput
          v-model="form.data.cardNumber"
          v-maska
          data-maska="####-####-####-####"
          placeholder="Card Number"
          class="mb-2"
          variant="none"
          :ui="inputStyles"
        >
          <template #trailing>
            <img 
              v-if="cardType === 'visa'" 
              src="/images/visa-badge.png" 
              width="35" 
              height="24"
            />
            <img
              v-if="cardType === 'mastercard'"
              src="/images/mastercard-badge.png"
              width="35"
              height="24"
            />
            <font-awesome-icon 
              v-if="['discover', 'troy', 'amex'].includes(cardType)"
              icon="fa-light fa-ban" 
              class="text-[#EA1662]" 
            />
          </template>
        </UInput>
      </FormGroup>

      <!-- NAME -->
      <FormGroup label="Name on Card" name="name">
        <UInput
          v-model="form.data.name"
          placeholder="Name on Card"
          class="mb-2"
          variant="none"
          :ui="inputStyles"
        />
      </FormGroup>

      <div class="flex flex-row items-center justify-between gap-4">
        <!-- EXPIRATION -->
        <FormGroup label="Expiry Date"  name="expirationDate">
          <UInput
            v-model="form.data.expirationDate"
            v-maska
            data-maska="##/##"
            placeholder="MM / YY" 
            class="w-full"
            variant="none"
            :ui="inputStyles"
          />
        </FormGroup>

        <!-- CVV -->
        <FormGroup label="CVV" name="securityCode">
          <UInput
            v-model="form.data.securityCode"
            :type="cvvVisible ? 'text' : 'password'"
            placeholder="CVV"
            class="w-full"
            v-maska
            data-maska="####"
            variant="none"
            :ui="inputStyles"
          >
          <template #trailing>
            <UButton 
              v-if="cvvVisible" 
              variant="link" 
              @click="cvvVisible = !cvvVisible"
            > 
              <font-awesome-icon icon="fa-light fa-eye" />
            </UButton>
            <UButton 
              v-else
              variant="link"
              @click="cvvVisible = !cvvVisible"
            >
              <font-awesome-icon icon="fa-light fa-eye-slash" />
            </UButton>
          </template>
          </UInput>
        </FormGroup>
      </div>

      <!-- COUNTRY -->
      <FormGroup label="Billing Country" name="country">
        <CountrySelect 
          v-model="form.data.country" 
          placeholder="Country" 
        />
      </FormGroup>

      <!-- POSTAL CODE -->
      <FormGroup 
        v-if="countryWithPostalCodes"
        label="Zip / Postal Code"
        name="postalCode"
        class="animate-fade-up"
      >
        <UInput
          v-model="form.data.postalCode"
          placeholder="Zip / Postal Code"
          variant="none"
          :ui="inputStyles"
        />
      </FormGroup>
      <UDivider />

      <!-- PRIMARY -->
      <FormGroup label="" name="primaryAccount" class="animate-fade-up">
        <UCheckbox
          v-model="form.data.primary"
          type="checkbox"
          label="Make this my default payment method"
          name="primaryAccount"
        />
      </FormGroup>

      <UDivider />

      <Button 
        type="submit" 
        :block="true" 
        :loading="loading" 
        :disabled="!allowSubmit"
      >
        <span v-if="loading">Processing...</span>
        <span v-else>Save Card</span>  
      </Button>
    </UForm>
  </ClientOnly>
</template>

<script setup>
  import { object, string, number } from 'yup'
  import { useUserStore } from "@/store/user"

  const props = defineProps({
    mode: {
      type: String,
      default: "create"
    },
    paymentAccount: {
      type: Object,
      default: null
    }
  })

  const emit = defineEmits([
    'new-card'
  ])

  const userStore   = useUserStore()
  const config      = useRuntimeConfig()
  const toast       = useToast()

  const allowSubmit = ref(true)
  const loading     = ref(false)
  const cvvVisible  = ref(false)
  const formErrors  = ref([])

  const form = reactive({
    data: {
      cardNumber:     null,
      cardType:       null,
      country:        null,
      expirationDate: null,
      name:           null,
      postalCode:     null,
      securityCode:   null,
      primary:        !userStore.hasPrimaryPaymentAccount,
    },
    errors: null
  })

  const schema = object({
    name: string()
      .required('Invalid Name on Card'),
    country: string()
      .required('Invalid Country'),
    securityCode: string()
      .min(3, 'Invalid CVV')
      .required('Invalid CVV'),
    cardNumber: string()
      .test('validateCardType', 
        (d) => `Must be Visa or Mastercard`,
        (value) => cardType.value === "visa" || cardType.value === "mastercard"
      )
      .test('validateLuhnCheck',
        (d) => `Invalid Card Number`,
        (value) => cardNumberIsValid.value
      )
      .min(13, 'Invalid Card Number')
      .required('Invalid Card Number'),
    expirationDate: string()
      .test('validateExpirationDate', 
        (d) => `Invalid Expiry Date`,
        (value) => {
          if (!value) return false
          const [month, year] = value.split("/")
          const now = new Date()
          const currentYear = now.getFullYear()
          const currentMonth = now.getMonth() + 1
          return (
            parseInt(`20${year}`) > currentYear ||
            (parseInt(`20${year}`) >= currentYear && parseInt(month) >= currentMonth)
          )
        }
      )
      .required('Invalid Expiry Date'),
    postalCode: string()
      .min(4, 'Invalid Zip/Postal Code')
      .when('country', {
        is: (country) => ["US", "CA", "GB", "AU", "NZ"].includes(country),
        then: () => string().required('Invalid Zip/Postal Code'),
        otherwise: () => string().notRequired()
      })
  })

  const cardNumberIsValid = computed(() => {
    let number = null
    if (form.data.cardNumber) {
      number = form.data.cardNumber.replace(/-/g, "")
    }
    const regex = new RegExp("^[0-9]{13,19}$")
    if (form.data.cardNumber && !regex.test(number)) return false

    const luhnCheck = (val) => {
      let checksum = 0
      let j = 1
      for (let i = val.length - 1; i >= 0; i--) {
        let calc = 0
        calc = Number(val.charAt(i)) * j
        if (calc > 9) {
          checksum = checksum + 1
          calc = calc - 10
        }
        checksum = checksum + calc
        if (j == 1) {
          j = 2
        } else {
          j = 1
        }
      }
      return checksum % 10 == 0
    }
    return luhnCheck(number)
  })

  const cardType = computed(() => {
    let result = null
    if (form.data.cardNumber) {
      let number = form.data.cardNumber
      let re = new RegExp("^4")
      if (number.match(re) != null) result = "visa"
  
      re = new RegExp("^(34|37)")
      if (number.match(re) != null) result = "amex"
  
      re = new RegExp("^5[1-5]")
      if (number.match(re) != null) result = "mastercard"
  
      re = new RegExp("^6011")
      if (number.match(re) != null) result = "discover"
  
      re = new RegExp("^9792")
      if (number.match(re) != null) result = "troy"
    }
    form.data.cardType = result
    return result
  })

  const countryWithPostalCodes = computed(() => {
    return ["US", "CA", "GB", "AU", "NZ"].includes(form.data.country)
  })

  const validate = async () => {
    try {
      await schema.validate(form.data, { abortEarly: false })
      formErrors.value = []
      allowSubmit.value = true
      return true
    } catch (err) {
      allowSubmit.value = false
      formErrors.value = err.errors.filter(Boolean)
      return false
    }
  }

  const handleSubmit = async () => {
    loading.value     = true
    allowSubmit.value = false

    const valid = await validate()

    if (valid) {
      const payload = {
        payment_account: {
          payment_name:             "Default",
          card_name:                form.data.name,
          card_type:                cardType.value,
          card_number:              form.data.cardNumber.replace(/-/g, ''),
          card_expiration_month:    form.data.expirationDate.split("/")[0],
          card_expiration_year:     form.data.expirationDate.split("/")[1],
          card_verification_value:  form.data.securityCode,
          postal_code:              form.data.postalCode,
          country:                  form.data.country,
          primary_account:          form.data.primary,
        }
      }

      try {
        const card = await $api(`${config.public.API_URL}/api/payment_accounts`,{ method: "POST", body:   payload })
        userStore.getData()
        toast.add({ title: "Payment method added!" })
        emit('new-card', card)
      } catch (error) {
        loading.value     = false
        allowSubmit.value = true
      }
    } else {
      loading.value     = false
      allowSubmit.value = true
    }
  }

  const inputStyles = {  
    variant: {
      none: 'font-semibold ring-1 ring-[#E4E4E4] '+
            'focus:ring-1 focus:ring-[#f26998] '+
            'dark:ring-[#384247] dark:bg-[#1b2022] '+
            'dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]'
    },
    icon: {
      trailing: {
        pointer: ''
      }
    }
  }
</script>