<template>
  <div class="flex flex-col">
    <FormWysiwyg
      v-model="form.text"
      :value="form.text"
      :schedule="form.scheduled"
      :media="form.media"
      toolbarGroup="basic"
      pluginGroup="standard"
      @clear="resetForm"
      @input="handleInput"
      @media="handleMedia"
      @schedule="handleScheduled"
      placeholder="Create a new post..."
    />

    <Errorbox :errors="form.errors" />

    <div class="flex flex-row items-center justify-end mb-2">
      <div class="flex flex-row items-center gap-2">
        <PriceButton
          v-if="showPriceButton"
          primaryLabel="Set Price"
          secondaryLabel="Schedule Post"
          theme="primary"
          size="md"
          :tokenCount="form.price"
          :showInput="showPriceForm"
          :error="form.errors"
          @price-set="handlePriceSet"
          @post="createPost"
        />
        <Button
          v-if="!showPriceButton"
          @click="createPost" theme="primary"
        >
          {{ postButtonLabel }}
        </Button>
      </div>
    </div>
  </div>
  <UModal
    v-model="becomeACreatorShowing"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <div class="flex flex-col gap-3 px-4 py-6">
      <h2 class="text-xl">
        Be the next and only
        <br class="hidden md:block lg:block" />
        <span class="text-primary-600">Favorite</span>
        for your fans
      </h2>

      <p class="mb-2">
        Start earning money by creating content for your fans. Get started by
        verifying your account.
      </p>

      <div class="text-center">
        <Button
          size="xl"
          class="text-lg"
          @click="navigateTo('/settings/verification')"
        >
          Get Started
        </Button>
      </div>
    </div>
  </UModal>
</template>

<script setup>
  import { useUserStore } from "@/store/user"


  const props = defineProps({
    scheduled: {
      type: String,
      default: null,
    },
  })

  const emit = defineEmits([
    "broadcast-feed-update"
  ])

  const userStore = useUserStore()
  const becomeACreatorShowing = ref(false)
  const showPriceForm = ref(false)
  const currentRoute = useRoute()

  const form = reactive({
    errors: [],
    pending: false,
    text: "",
    price: null,
    scheduled: props.scheduled,
    media: [],
  })

  const resetForm = () => {
    showPriceForm.value = false
    form.errors = []
    form.pending = false
    form.text = ""
    form.price = null
    form.scheduled = null
    form.media = []
  }

  const showPriceButton = computed(() => {
    if (form.media.length) {
      const lockedMedia = form.media.filter((m) => m.locked)
      if (lockedMedia.length) {
        return true
      }
    } else {
      return false
    }
  })

  const postButtonLabel = computed(() => {
    return form.scheduled ? "Schedule Post" : "Post"
  })

  const priceButtonLabel = computed(() => {

  })

  const handleInput = (text) => {
    form.text = text
  }

  const handlePriceSet = (value) => {
    form.price = value
  }

  const handleMedia = (data) => {
    form.media = data
    showPriceForm.value = true
  }

  const handleScheduled = (schedule) => {
    form.scheduled = schedule
  }

  const createPost = async () => {
    form.errors = []

    if (userStore.approvedCreator) {
      if (!form.text && !form.media.length) return

      let hasLockedMedia = form.media
        ?.filter((m) => m.locked)
        .filter(Boolean).length
        || false

      if (hasLockedMedia && !form.price) form.errors.push("Locked media requires you set a token price for your post.")
      if (!form.text) form.errors.push("Add a message to your post.")
      if (form.errors.length) return

      try {
        form.pending = true

        const config = useRuntimeConfig()
        const scheduledAt = form.scheduled
          ? new Date(form.scheduled)
          : new Date()
        const { error, data } = await useAuthFetch(
          `${config.public.API_URL}/api/posts`,
          {
            method: "POST",
            body: {
              post: {
                text: form.text,
                tokenCount: +form.price,
                scheduledAt: scheduledAt,
                media: form.media,
              },
            },
          },
        )

        if (error.value) {
          console.log("error: ", error.value)
          form.errors = useErrorFormatter(error.value.data)
        } else {
          resetForm()
          emit("broadcast-feed-update", data)
          const targetRoute = `/${userStore.username}`
          if (currentRoute.path !== targetRoute) {
            await navigateTo(targetRoute)
          }
        }
      } catch (error) {
        if (error.message) form.errors = error.message
      } finally {
        form.pending = false
      }
    } else {
      becomeACreatorShowing.value = true
    }
  }
</script>
