<template>
  <div class="relative group">
    <div
      class="sm:hidden group-hover:block absolute -top-2 -right-2 z-50"
      @click="removeMedia"
    >
      <font-awesome-icon
        icon="fa-solid fa-circle-xmark"
        class="rounded-full text-lg text-gray-200 border-4 border-white dark:border-[#1B2022]"
      />
    </div>

    <div class="thumb">
      <img
        :src="media.previewUrl || media.signedThumbnailUrl"
        class="w-[60px] h-[60px]"
      />

      <div class="wrapper">
        <div v-if="media.locked" class="lock locked">
          <font-awesome-icon icon="fa-light fa-lock" />
        </div>
        <div v-else class="lock unlocked">
          <font-awesome-icon icon="fa-light fa-lock-open" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    locked: Boolean,
    duration: String,
    media: Object,
  })

  const emit = defineEmits(["remove"])
  const removeMedia = (e) => {
    e.stopPropagation()
    emit("remove", props.media)
  }
</script>

<style lang="scss" scoped>
  .thumb {
    @apply relative text-white rounded-lg overflow-hidden;

    .wrapper {
      @apply absolute h-full inset-0 px-3 py-2 rounded-lg overflow-hidden bg-black bg-opacity-25 flex flex-col items-center justify-center transition-colors;

      &:hover {
        @apply bg-white bg-opacity-50;
      }
    }

    .lock {
      @apply h-full flex items-center justify-center rounded-full;

      aspect-ratio: 1/1;

      &.locked {
        @apply bg-pink-600 bg-opacity-75;
      }

      &.unlocked {
        @apply bg-black bg-opacity-75;
      }
    }
  }
</style>
