import { useUserStore } from "@/store/user"
import { defu } from "defu"

export function useAuthFetch(url, options) {
  const userStore = useUserStore()
  const config = useRuntimeConfig()
  const headers = {
    "Content-Type": "application/json",
  }

  if (userStore.loggedIn) {
    headers.Authorization = `Bearer ${userStore.token}`
  }

  const defaults = {
    // TODO: This doesn't work
    // baseURL: "http://why:666/laksjdflkjasdf/",
    headers: headers,
    onResponseError: async ({ request, response, options }) => {
      if (response.status === 401) {
        try {
          const data = await $fetch(
            `${config.public.API_URL}/api/refresh_token`,
            {
              method: "POST",
              body: { refreshToken: userStore.refreshToken },
              headers: {
                "Content-Type": "application/json",
              },
            },
          )
          userStore.refreshSession(data.token, data.refreshToken)
        } catch (error) {
          console.error("Failed to refresh token", error)
          userStore.logout()
          return Promise.reject(response)
        }
      }
    },
  }

  return useFetch(url, defu(options, defaults))
}
