<template>
  <div class="wrapper">
    <div class="overlay"></div>
    <div :class="isMobile ? 'position-top' : 'position-center'">
      <div class='z-20 animate-fade rounded-lg' :class="isMobile ? 'w-full' : 'w-[600px]'">
        <Modal title="Edit Media Tags" @close="handleClose(false)">
          <div v-if="media.type === 'image'" class="object-contain">
            <img :src="media.signedUrl" loading="lazy" class="w-full rounded-lg" />
          </div>

          <FormGroup label="Tags" hint="Separate tags with commas">
            <Input v-model="newTags" placeholder="tags" :autofocus="true" />
          </FormGroup>

          <div class="w-full flex flex-row flex-wrap gap-1.5">
            <div v-for="tag in tagsToSubmit" :key="tag">
              <div class="chip">
                <span class="pr-2">{{ tag }}</span>
                <font-awesome-icon @click="removeTagToSubmit(tag)" icon="fa-solid fa-xmark" class="btn-dismiss"
                  style="aspect-ratio: 1/1" />
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center justify-end gap-1.5">
            <Button theme="outline" @click="handleClose(false)">
              Cancel
            </Button>
            <Button :disabled="disabled" :loading="loading" @click="updateMedia">
              Save
            </Button>
          </div>

        </Modal>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  media: {
    type: Object,
    required: true,
  },
  tags: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits([
  'save',
  'close',
  'refresh'
])

const config = useRuntimeConfig()
const isMobile = useMediaQuery("(max-width: 768px)")
const toast = useToast()

const newTags = ref('')
const tagsToRemove = ref([])
const loading = ref(false)

const tagsToSubmit = computed(() => {
  return [
    ...new Set(
      newTags.value
        .split(",")
        .concat(props.tags)
        .map((t) => t.trim().slice(0, 50))
        .filter((t) => tagsToRemove.value.indexOf(t) === -1)
        .filter((t) => t.trim() !== ""),
    )
  ]
})

const removeTagToSubmit = (tag) => {
  tagsToRemove.value.push(tag)
  newTags.value = newTags.value
    .split(",")
    .filter((t) => t.trim() !== tag)
    .join(",")
}

const handleClose = (value) => {
  newTags.value = ''
  tagsToRemove.value = []
  emit('close', value)
}

const updateMedia = async () => {
  loading.value = true
  await useAuthFetch(`${config.public.API_URL}/api/media/bulk`, {
    method: "POST",
    body: {
      media_ids: [props.media.id],
      new_tags: tagsToSubmit.value,
    },
  })

  toast.add({ title: "Media Tags Updated", theme: "success" })
  setTimeout(() => {
    loading.value = false
    emit("refresh")
    handleClose(true)
  }, 1000)
}

const disabled = computed(() => {
  return tagsToSubmit.value.length === props.tags.length
    && tagsToRemove.value.length === 0
    && newTags.value === ""
    && !loading.value
})
</script>

<style scoped lang="scss">
.wrapper {
  @apply fixed top-0 left-0 h-screen w-screen z-10;
}

.overlay {
  @apply fixed inset-0 transition-opacity bg-gray-200/75 dark:bg-gray-800/75 z-10;
}

.position-center {
  @apply w-screen h-screen flex flex-col justify-center items-center;
}

.position-top {
  @apply absolute top-0 w-full flex flex-col justify-start items-center p-4;
}

.chip {
  @apply py-2 px-3 bg-gray-200 rounded-full flex items-center gap-1;
  @apply dark:bg-[#2b3236];
}

.btn-dismiss {
  @apply rounded-full p-1 transition-colors cursor-pointer;
  @apply bg-gray-300 dark:bg-gray-900 hover:bg-primary-600 dark:hover:bg-primary-500;
  @apply hover:bg-primary-600 hover:text-white;
}
</style>