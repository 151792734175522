<template>
  <header class="flex flex-row items-center justify-between w-full md:px-4">
    <IdentityCard v-if="creator && !isMobile" :user="creator" />

    <div v-if="isMobile" class="flex flex-row items-center justify-start h-[42px] cursor-pointer"
      @click="handleIdentityClick">

      <div class="flex flex-col justify-start items-start whitespace-nowrap">
        <span class="text-xs">
          {{ creator.displayName }}
          <font-awesome-icon v-if="creator.isCreator" icon="fa-solid fa-badge-check" class="text-pink-600" />
        </span>
        <span class="text-xs text-gray-400">@{{ creator.username }}</span>
      </div>

    </div>

    <div v-if="slideCount > 1" class="whitespace-nowrap w-full text-center">
      {{ slideIndex }} / {{ slideCount }}
    </div>

    <div id="actions" class="flex flex-row items-center justify-end gap-4">
      <!-- Edit Button -->
      <Button v-if="showEditButton" size="xs" @click="emit('edit')">
        <font-awesome-icon icon="fa-light fa-pencil" />
        Edit Tags
      </Button>

      <!-- Close Button -->
      <font-awesome-icon icon="fa-light fa-circle-xmark" size="xl" @click="emit('close')" />
    </div>
  </header>
</template>

<script setup>
const props = defineProps({
  creator: {
    type: Object,
    required: true,
  },

  slideIndex: {
    type: Number,
    required: true,
  },

  slideCount: {
    type: Number,
    required: true
  },

  slideId: {
    type: String,
    default: null
  },

  editable: {
    type: Boolean,
    default: false
  },

  status: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['edit', 'close'])
const isMobile = useMediaQuery("(max-width: 768px)")

const handleIdentityClick = () => {
  emit('close')
  navigateTo(`/${props.creator.username}`)
}

const showEditButton = computed(() => {
  return props.editable && (props.creator?.isCreator || props.creator.approvedCreator) && props.status != 'UserTagsNeeded'
})
</script>