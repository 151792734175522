<template>
  <div class="flex flex-col justify-center items-center gap-4">
    <div class="h-dvh flex flex-col md:flex-row justify-center items-center">
      <!-- IMAGE: Locked -->
      <div v-if="asset.type === 'image' && !asset.hasAccess && asset.locked"
        class="w-full h-full relative grow rounded-lg overflow-hidden">
        <div class="absolute w-full h-full z-10 flex flex-col justify-center items-center gap-4">
          <font-awesome-icon icon="fa-light fa-lock-keyhole" class="fa-fw" size="xl" />
        </div>
        <BlurHashThumb v-bind="$attrs" :blur-hash="asset.blurhash" />
      </div>

      <!-- IMAGE: Unlocked -->
      <div v-if="asset.type === 'image' && (asset.hasAccess || !asset.locked)" class="order-2 sm:order-1">
        <img :src="asset.signedUrl" loading="lazy"
          class="w-full h-auto object-contain object-center rounded-lg max-w-[1200px]" />
      </div>

      <!-- VIDEO: Locked -->
      <div v-if="asset.type === 'video' && asset.locked" class="h-full w-full rounded-lg overflow-hidden">
        <div class="absolute w-full h-full z-10 flex flex-col justify-center items-center gap-4">
          <font-awesome-icon icon="fa-light fa-lock-keyhole" class="fa-fw" size="xl" />
          <div class="video-duration">
            <font-awesome-icon icon="fa-solid fa-play" size="xs" class="fa-fw" />
            {{ formattDuration(asset.durationInSeconds) }}
          </div>
        </div>
        <BlurHashThumb v-bind="$attrs" :blur-hash="asset.blurhash" fullsize />
      </div>

      <!-- VIDEO: Unlocked -->
      <div v-if="asset.type === 'video' && !asset.locked" class="rounded-lg overflow-hidden">
        <VideoPlayer :src="asset.signedUrl" />
      </div>

      <!-- CALLOUT: Purchase Media -->
      <MediaModalPurchase v-if="asset.locked && !asset.hasAccess" :mode="mode" :referenceId="referenceId"
        @post-purchase="handlePostPurchase" @message-purchase="handleMessagePurchase" />

      <!-- CALLOUT: Tag People -->
      <MediaModalTagPeople v-if="asset.status == 'UserTagsNeeded'" :media="asset" @update-medium="handleUpdateMedium" />
    </div>

    <footer class="flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-2 w-full py-2 md:px-4">
      <div id="datetime">
        <span v-if="createdAt" class="text-xs">{{ $dayjs(createdAt).format('MMM D, YYYY') }} @ {{
        $dayjs(createdAt).format('hh:mmA') }}</span>
      </div>
      <div id="tags" class="flex flex-row justify-end items-center gap-2">
        <UBadge v-for="tag in asset.tags" color="pink" variant="soft" size="xs">
          {{ tag }}
        </UBadge>

        <UBadge color="pink" variant="soft" size="xs">
          {{ asset.nsfw ? 'NSFW' : 'SFW' }}
        </UBadge>
      </div>
    </footer>
  </div>
</template>

<script setup>
const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },

  mode: {
    type: String,
    required: true,
  },

  referenceId: {
    type: String,
    default: null,
  },

  createdAt: {
    type: String,
    default: null
  }
})

const emit = defineEmits([
  'post-purchase',
  'message-purchase',
  'update-medium'
])

const handleUpdateMedium = (creators) => {
  emit("update-medium", creators)
}

const handlePostPurchase = (postId) => {
  emit("post-purchase", postId)
}

const handleMessagePurchase = (messageId) => {
  emit("message-purchase", messageId)
}
</script>