<template>
  <div class="relative">
    <div class="flex flex-row items-center justify-center gap-2">
      <Button 
        v-if="!inputOpened" 
        :theme="theme"
        :size="size"
        @click="openInputField"
      >
        <font-awesome-icon icon="fa-light fa-coins"/>
        {{ primaryLabelValue }}
      </Button>
      <FormInput 
        v-if="inputOpened"
        v-model="tokens"
        type="number"
        placeholder="Tokens.." 
        :ui="inputStyles"
        class="animate-fade no-spinners"
        @input="handleTokenInput"
      >
        <template #leading>
          <font-awesome-icon icon="fa-light fa-coins" color="gray"/>
        </template>
        <template #trailing>
          <Button 
            size="xs"
            @click="handlePost"
          >
            {{ secondaryLabelValue }}
          </Button>
        </template>
      </FormInput>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    theme: {
      type: String,
      default: "outline"
    },
    primaryLabel: {
      type: String,
      default: "Price"
    },
    secondaryLabel: {
      type: String,
      default: "Post"
    },
    size: {
      type: String,
      default: "md"
    },
    showInput: {
      type: Boolean,
      default: false
    },
    tokenCount: {
      type: Number,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: "create"
    }
  })

  const emit = defineEmits([
    'price-set',
    'post'
  ])

  const inputOpened = ref(true)

  watch(() => props.showInput, () => {
    inputOpened.value = props.showInput
  })

  watch(() => props.tokenCount, () => {
    inputOpened.value = props.tokenCount >= 1
      ? true
      : false
  })

  const tokens = ref(props.tokenCount)
  
  const primaryLabelValue = computed(() => {
    return tokens.value ? tokens.value : props.primaryLabel
  })

  const secondaryLabelValue = computed(() => {
    return props.mode === 'create' ? 'Post Now' : 'Update Post'
  })
  
  const openInputField = () => {
    inputOpened.value = true
  }

  const handleTokenInput = async (event) => {
    emit('price-set', event.target.value)
  }

  const handlePost = async () => {
    emit('post')
  }

  const handleSave = () => {
    inputOpened.value = false
    emit('price-set', tokens)
  }

  const inputStyles = {
    wrapper: 'relative text-sm',
    rounded: 'rounded-full',
    padding: 'lg',
    variant: {
      none: 'font-semibold ring-1 ring-[#E4E4E4] '+
            'focus:ring-1 focus:ring-[#f26998] '+
            'dark:ring-[#384247] dark:bg-[#1b2022] '+
            'dark:focus:ring-[#f26998] dark:focus:bg-[#2b3236]'
    },
    icon: {
      trailing: {
        wrapper: 'absolute inset-y-0 end-0 flex items-center !px-1',
        pointer: ''
      }
    },
  }
</script>