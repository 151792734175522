export const useErrorFormatter = (errorObj) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const camelCaseToNormalText = (input) => {
    const result = input.replace(/([_])/g, " ").toLowerCase()
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const output = Object.entries(errorObj).flatMap(([field, messages]) => {
    return camelCaseToNormalText(field) + " " + messages.join(", ") + "."
  })

  return output
}