<template>
  <div class="sm:order-2 order-1 flex flex-col gap-3 p-3 rounded-lg z-20 min-w-[375px] mt-8">
    <p class="font-semibold">Unlock this post and all it's media for<br /> 3 tokens.</p>
    <Button theme="primary" size="lg" block @click="handlePurchase">
      <font-awesome-icon icon="fa-light fa-lock-open" class="fa-fw" />
      Unlock Now
    </Button>
  </div>
</template>

<script setup>
const props = defineProps({
  mode: {
    type: String,
    default: "post", // post, message
  },
  referenceId: {
    type: String,
    required: true
  }
})

const emit = defineEmits([
  'post-purchase',
  'message-purchase'
])

const handlePurchase = () => {
  props.mode === 'post'
    ? emit('post-purchase', props.referenceId)
    : emit('message-purchase', props.referenceId)
}
</script>