<template>
  <aside class="site-navigation text-sm">
    <nav class="nav-container scrollable no-scrollbar">
      <NuxtLink
        v-for="(link, index) in links"
        :key="index"
        :to="link.to"
        class="menu-item"
        :class="{ active: isPathActive(link.to) }"
        @click="link.click"
      >
        <div
          v-if="link.icon"
          class="relative flex flex-col items-center mx-auto lg:mx-0"
        >
          <span v-if="link.badgeCount" class="badge">{{ link.badgeCount }}</span>
          <font-awesome-icon :icon="link.icon" class="icon" />
        </div>
        <div class="label">
          {{ link.label }}
        </div>
      </NuxtLink>

      <!-- MORE MENU -->
      <UPopover
        overlay
        :popper="{ arrow: true, placement: 'right-start' }"
        class="w-full"
      >
        <div class="menu-item">
          <span class="flex flex-col items-center mx-auto lg:mx-0">
            <font-awesome-icon icon="fa-light fa-ellipsis" class="icon" />
          </span>
          <span class="label">More</span>
        </div>

        <template #panel="{ close }">
          <SiteExpandedNav :close="close" />
        </template>
      </UPopover>

      <!-- POST BUTTON -->
      <!--
      <ClientOnly>
        <UButton
          v-if="userStore.loggedIn"
          color="primary"
          size="lg"
          class="sm:hidden md:block lg:block rounded-full w-full mt-6"
          @click="postShowing = true"
        >
          <font-awesome-icon v-if="isMobile || isTablet" icon="fa-solid fa-plus" size="lg"/>
          <span v-else>New Post</span>
        </UButton>
      </ClientOnly>
      -->
    </nav>

    <!-- USER CARD -->
    <div v-if="userStore.loggedIn" class="bottom-user-card">
      <UPopover overlay :popper="{ placement: 'top' }">
        <div class="flex flex-row justify-start items-center gap-2">
          <Avatar size="md" :src="userStore.avatarUrl" />
          <div
            class="flex flex-col text-sm whitespace-nowrap sm:hidden lg:block"
          >
            <div class="text-[#2F2E2E] font-bold mb-1">
              {{ userStore.displayName }}
            </div>
            <div class="text-xxs dark:text-[#555F63]">
              @{{ userStore.username }}
            </div>
          </div>
        </div>

        <template #panel>
          <VerticalNavigation
            class="p-1"
            :links="[
              {
                icon: 'fa-light fa-gear',
                label: 'Profile Settings',
                to: '/settings/profile',
              },
              {
                icon: 'fa-light fa-right-to-bracket',
                label: 'Log Out',
                click: userStore.logout,
              },
            ]"
          />
        </template>
      </UPopover>
    </div>
  </aside>
</template>

<script setup lang="ts">
  import { useUserStore } from "@/store/user"

  const loginShowing = inject("loginModalOpen")
  const joinShowing = inject("signupModalOpen")
  const postShowing = inject("postModalOpen")

  const route = useRoute()
  const currentPath = computed(() => route.path)
  const isPathActive = (path: string) => {
    return currentPath.value == path
  }

  const userStore = useUserStore()
  const isMobile = useMediaQuery("(max-width: 640px)")
  const isTablet = useMediaQuery("(max-width: 1025px)")

  const config = useRuntimeConfig()
  const { data: notificationCounts } = await useAuthFetch(config.public.API_URL + "/api/notifications/unread_count")

  const links = computed(() => {
    const links = []

    links.push({
      label: "Home",
      icon: "fa-light fa-home",
      to: "/",
    })

    if (userStore.loggedIn) {
      links.push({
        label: "Notifications",
        icon: "fa-light fa-bell",
        to: "/notifications",
        badgeCount: notificationCounts.value && notificationCounts.value.unread,
      })

      links.push({
        label: "Messages",
        icon: "fa-light fa-envelope",
        to: "/messages",
      })

      links.push({
        label: "Favorites",
        icon: "fa-light fa-user-check",
        to: "/favorites",
      })

      if (userStore.approvedCreator) {
        links.push({
          label: "Media",
          icon: "fa-light fa-image",
          to: "/media",
        })
      }

      // if (userStore.approvedCreator) {
      //   links.push({
      //     label: "Statements",
      //     icon: "fa-light fa-chart-simple",
      //     to: "/statements",
      //   })
      // }

      if (userStore.approvedCreator || userStore.affiliate) {
        links.push({
          label: "Analytics",
          icon: "fa-light fa-chart-line-up",
          to: "/analytics-and-reports",
        })
      }

      if (userStore.approvedCreator) {
        links.push({
          label: "Calendar",
          icon: "fa-light fa-calendar",
          to: "/calendar",
        })
      }

      links.push({
        label: "Purchases",
        icon: "fa-light fa-image",
        to: "/purchases",
      })

      links.push({
        label: "Wallet",
        icon: "fa-light fa-wallet",
        to: "/wallet",
      })

      if (userStore.approvedCreator) {
        links.push({
          label: "My Profile",
          icon: "fa-light fa-circle-user",
          to: userStore.profileUrl,
        })
      }
    } else {
      links.push({
        label: "Login",
        icon: "fa-light fa-right-to-bracket",
        click: () => {
          loginShowing.value = true
        },
      })

      links.push({
        label: "Join",
        icon: "fa-light fa-user",
        click: () => {
          joinShowing.value = true
        },
      })
    }

    return links
  })
</script>

<style lang="scss" scoped>
  .site-navigation {
    @apply h-full flex flex-col justify-between items-center;
    @apply hidden;
    @apply md:flex md:min-w-[52px] md:min-w-[52px];
    @apply lg:min-w-[160px];
  }
  .nav-container {
    @apply w-full h-full flex flex-col justify-start items-center gap-2;
  }
  .scrollable {
    @apply overflow-y-auto grow;
  }

  .branding {
    @apply flex justify-center items-center;
    @apply mx-auto py-2 mb-4;
    @apply sm:mt-14 md:mt-0;
  }

  .menu-item {
    @apply w-full flex flex-row items-center sm:justify-center lg:justify-start gap-3;
    @apply px-[1.5rem] py-1.5 rounded-full;
    @apply cursor-pointer;

    .icon {
      @apply text-cool-600 mx-auto h-5 w-5 lg:mx-0;
      @apply dark:text-[#555F63];
    }

    .label {
      @apply sm:hidden lg:block;
      @apply text-[#595858];
      @apply dark:text-[#555F63];
    }

    &:hover,
    &.active {
      @apply bg-white transition-colors;
      @apply dark:bg-[#1B2022];

      .label {
        @apply text-[#2F2E2E];
        @apply dark:text-white;
      }

      .icon {
        @apply text-pink-500;
      }
    }
  }
  .bottom-user-card {
    @apply w-full flex flex-col sm:items-center lg:items-start cursor-pointer p-2 pb-1.5 rounded-lg mb-1 leading-none;
    @apply hover:bg-gray-300 dark:hover:bg-[#1B2022];
  }

  .badge {
    @apply absolute -top-2 -right-2 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-primary-500 rounded-full;
  }
</style>
